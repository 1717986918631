<template>
  <div class="mt-3">
    <div class="deposit-title mt-5 mt-md-1">
      <p>Способы оплаты</p>
    </div>
    <div class="row mt-2"
         v-if="depositMethods.card.length || depositMethods.sbp.length || depositMethods.wallet.length">
      <div class="col-12 col-lg-4 method-block">
        <div class="d-none d-md-block">
          <div
              v-for=" (method, index) in depositMethods.wallet" :key="index"
              v-if="method.method_module === 'nicepay'"
              @click="selectMethod(method)"
          >
            <PaymentMethod
                :methodPay="method"
                :activeMethod="selectedMethod.id ? selectedMethod.id : method.id"
                ref="paymentMethods"
            />
          </div>
          <div
              v-if="depositMethods.card.length"
              @click="blockMethod(cardMethod)"
          >
            <PaymentMethod
                :methodPay="cardMethod"
                :activeMethod="selectedMethod.id"
                ref="cardPaymentMethods"
            />
          </div>
          <div
              v-if="depositMethods.sbp.length"
              @click="blockMethod(sbpMethod)"
          >
            <PaymentMethod
                :methodPay="sbpMethod"
                :activeMethod=selectedMethod.id
                ref="paymentMethods"
            />
          </div>
          <div
              v-if="depositMethods.transfer.length"
              @click="blockMethod(transferMethod)"
          >
            <PaymentMethod
                :methodPay="transferMethod"
                :activeMethod=selectedMethod.id
                ref="paymentMethods"
            />
          </div>
          <div
              v-if="depositMethods.sberPay.length"
              @click="blockMethod(sberPayMethod)"
          >
            <PaymentMethod
                :methodPay="sberPayMethod"
                :activeMethod=selectedMethod.id
                ref="paymentMethods"
            />
          </div>
          <div
              v-for=" (method, index) in depositMethods.wallet" :key="index"
              v-if="method.method_module !== 'nicepay'"
              @click="selectMethod(method)"
          >
            <PaymentMethod
                :methodPay="method"
                :activeMethod=selectedMethod.id
                ref="paymentMethods"
            />
          </div>
        </div>
        <div class="d-block d-md-none">
          <div class="select-block-mobile position-relative w-100 py-0 px-4" v-if="!isCardAndSbp">
            <div class="p-2 d-flex align-items-center w-100" @click="toggleDropdown" v-if="selectedMethod.module !== 'nicepay'">
              <img :src="selectedOption.image" :alt="selectedOption.label" :data-src="selectedOption.image"
                   class="selected-img">
              <span>{{ selectedOption.label }}</span>
              <span class="dropdown-arrow ml-auto"
                    :class="{ 'fa fa-angle-up': isDropdownOpen, 'fa fa-angle-down': !isDropdownOpen }"></span>
            </div>
              <div class="p-2  w-100" @click="toggleDropdown" v-else>
             <div  class=" d-flex align-items-center w-100"
             v-for=" (firstmethod, index) in depositMethods.wallet" :key="firstmethod.id || index"
             v-if="firstmethod.method_module === 'nicepay'">
              <img :src="getPictureUrl(firstmethod)" :alt="firstmethod.name" :data-src="getPictureUrl(firstmethod)"
                   class="selected-img">
              <span>{{ firstmethod.name }}</span>
              <span class="dropdown-arrow ml-auto"
                    :class="{ 'fa fa-angle-up': isDropdownOpen, 'fa fa-angle-down': !isDropdownOpen }"></span>
             </div>
            </div>
            <div class="dropdown w-100" v-show="isDropdownOpen">
              <div
                  class="p-2 d-flex align-items-center"
                  v-for=" (method, index) in depositMethods.wallet" :key="method.id || index"
                  v-if="method.method_module === 'nicepay'"
                  @click="selectMethod(method)"
              >
                <img :src="getPictureUrl(method)" :data-src="getPictureUrl(method)" class="dropdown-img mr-3">
                <span>{{ method.name }}</span>
              </div>
              <div
                  class="p-2 d-flex align-items-center"
                  v-if="depositMethods.card.length"
                  @click="blockMethod(cardMethod)"
              >
                <img :src="getPictureUrl(cardMethod)" :data-src="getPictureUrl(cardMethod)" class="dropdown-img mr-3">
                <span>{{ cardMethod.name }}</span>
              </div>
              <div
                  class="p-2 d-flex align-items-center"
                  v-if="depositMethods.sbp.length"
                  @click="blockMethod(sbpMethod)"
              >
                <img :src="getPictureUrl(sbpMethod)" :data-src="getPictureUrl(sbpMethod)" class="dropdown-img mr-3">
                <span>{{ sbpMethod.name }}</span>
              </div>
              <div
                  class="p-2 d-flex align-items-center"
                  v-if="depositMethods.transfer.length"
                  @click="blockMethod(transferMethod)"
              >
                <img :src="getPictureUrl(transferMethod)" :data-src="getPictureUrl(transferMethod)" class="dropdown-img mr-3">
                <span>{{ transferMethod.name }}</span>
              </div>
              <div
                  class="p-2 d-flex align-items-center"
                  v-if="depositMethods.sberPay.length"
                  @click="blockMethod(sberPayMethod)"
              >
                <img :src="getPictureUrl(sberPayMethod)" :data-src="getPictureUrl(sberPayMethod)" class="dropdown-img mr-3">
                <span>{{ sberPayMethod.name }}</span>
              </div>
              <div
                  class="p-2 d-flex align-items-center"
                  v-for=" (method, index) in depositMethods.wallet" :key="index"
                  @click="selectMethod(method)"
              >
                <img :src="getPictureUrl(method)" :data-src="getPictureUrl(method)" class="dropdown-img mr-3">
                <span>{{ method.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8 p-md-X">
        <div>
          <div class="deposit-title mt-5 mt-md-1" v-if="!isCardAndSbp">
            <p>Выберите оператора</p>
          </div>
          <div class="row" v-if="!selectedMethod.id && !isCardAndSbp">
            <div class="col-12 col-md-6"
                 v-for=" (method, index) in depositMethods.wallet" :key="index"
                 v-if="method.method_module === 'nicepay'"
                 @click="selectMethod(method)">
              <PaymentMethod
                  :methodPay="method"
                  :activeMethod=method.id
                  ref="cards"
              />
            </div>
          </div>
          <div class="row" v-else-if="selectedMethod.method_type == 'card' && !isCardAndSbp">
            <div class="col-12 col-md-6"
                 v-for=" (method, index) in depositMethods.card" :key="index"
                 @click="selectMethod(method)">
              <PaymentMethod
                  :methodPay="method"
                  :activeMethod=selectedMethod.id
                  ref="cards"
              />
            </div>
          </div>
          <div class="row" v-else-if="selectedMethod.method_type == 'sbp' && !isCardAndSbp">
            <div class="col-12 col-md-6"
                 v-for=" (method, index) in depositMethods.sbp" :key="index"
                 @click="selectMethod(method)">
              <PaymentMethod
                  :methodPay="method"
                  :activeMethod=selectedMethod.id
                  ref="sbp"
              />
            </div>
          </div>
          <div class="row" v-else-if="selectedMethod.method_type == 'transfer' && !isCardAndSbp">
            <div class="col-12 col-md-6"
                 v-for=" (method, index) in depositMethods.transfer" :key="index"
                 @click="selectMethod(method)">
              <PaymentMethod
                  :methodPay="method"
                  :activeMethod=selectedMethod.id
                  ref="sbp"
              />
            </div>
          </div>
          <div class="row" v-else-if="selectedMethod.method_type == 'sberpay' && !isCardAndSbp">
            <div class="col-12 col-md-6"
                 v-for=" (method, index) in depositMethods.sberPay" :key="index"
                 @click="selectMethod(method)">
              <PaymentMethod
                  :methodPay="method"
                  :activeMethod=selectedMethod.id
                  ref="sbp"
              />
            </div>
          </div>
          <div class="row" v-else>
            <div class="col-12 col-md-6 selected-method">
              <PaymentMethod
                  :methodPay="selectedMethod"
                  :activeMethod=selectedMethod.id
              />
            </div>
          </div>
          <div class="back-arrow-block d-flex d-lg-none mt-1">
            <span @click="backData(selectedMethod)" v-if="isCardAndSbp" class="back-arrow"><i class="fa fa-arrow-left"
                                                                                              aria-hidden="true"></i></span>
          </div>
          <div class="suma-block"
               v-if="(selectedMethod.name !== '' || selectedMethod.module === 'nicepay') &&  selectedMethod.id !== 1 && selectedMethod.id !== 2 && selectedMethod.id !== 3 && selectedMethod.id !== 4">
            <b-form
                id="deposit-form"
                class="my-4 mx-0 deposit-form"
                method="POST"
                :action="$_config.payInUrl"
                @submit="payInFormSubmit"
            >
              <b-row class="mx-0 mt-3">
                <div class="col-12 input-group px-0">
                  <input
                      id="depositAmount"
                      ref="depositAmount"
                      v-model="sum"
                      name="payin_amount"
                      class="form-control main-input"
                      placeholder="Сумма в рублях"
                      type="text"
                      required
                  >
                </div>
              </b-row>
              <div class="alert-text mt-3" v-if="amountAlert">
                <strong>
                  <span> Уважаемый клиент, пожалуйста, будьте внимательны, сумма будет изменена. Проверьте сумму на странице оплаты. </span>
                </strong>
              </div>
              <div class="promo-text" @click="promoCode" v-if="!isPromoCode">
                <span>У меня есть промокод</span>
              </div>
              <div v-if="isPromoCode">
                <b-row class="mx-0 mt-3">
                  <div class="col-12 input-group px-0">
                    <input
                        id="depositPromocode"
                        ref="depositPromocode"
                        class="form-control main-input"
                        placeholder="Депозитный промокод"
                        type="text"
                        name="depositPromocode"
                    >
                  </div>
                </b-row>
                <div class="promo-text" @click="promoCode">
                  <span>Не вводить промокод</span>
                </div>
              </div>

              <b-row v-if="sum" class="mt-4">
                <div class="col-12 input-group ">
                  <label class="col-form-label ">
                    Итого к оплате : {{ sum }} Руб {{ selectedMethod.name ? '- Метод : ' + selectedMethod.name : '' }}
                  </label>
                </div>
              </b-row>

              <b-col cols="12" class="px-0 col-md-12 col-lg-11 mx-0 mx-lg-auto">
                <Alert :message="formResponse"></Alert>
              </b-col>

              <b-row class="mt-3 text-center mx-0 col-12 px-0">
                <div class="col-12 col-lg-6 mx-lg-auto px-0 px-lg-3">
                  <div class="green-btn-bg">
                    <button class="btn red-btn w-100 text-white" type="submit">
                      Пополнить
                    </button>
                  </div>
                </div>
                <div class="form-group row">
                  <input v-model="selectedMethod.id" type="hidden" name="method">
                  <input v-model="selectedMethod.module" type="hidden" name="selected_system">
                </div>
              </b-row>
            </b-form>
          </div>
        </div>
        <div>
          <div v-if="userData && userData.can_get_double_promotion" class="py-4 text-center mx-0 col-12">
            <p>
              <strong class="text-red-gradient">
                <i class="fa fa-thin fa-gift zoom-in-zoom-out"></i> +100% BONUS к первому депозиту <br>
              </strong>
              <br>
              <span class="text-white bonus-text">
                      Сделайте своё первое пополнение и получите <strong class="text-uppercase">удвоение</strong>
                      своего депозита (удвоение будет только при первом пополнении)
                    </span>
            </p>
          </div>
          <div v-if="selectedMethod.name !== '' &&  selectedMethod.id !== 1 && selectedMethod.id !== 2 && selectedMethod.id !== 3 && selectedMethod.id !== 4 && userData"
               class="text-center mx-0 py-4 col-12 promo-activation-area">
            <span class="gray-color deposit-hint d-block">Минимальный депозит {{
                selectedMethod.min_amount
              }} Руб.</span>
            <span class="gray-color deposit-hint d-block">Максимальный депозит за раз {{
                selectedMethod.max_amount
              }} Руб.</span>
          </div>
          <div v-if="userData" class="py-4 text-center mx-0 col-12 promo-activation-area">
            <div class="col-12 col-sm-10  col-lg-6 mx-auto px-0">
              <button name="ActivatePromoCode"
                      class="btn btn-red-to-orange w-100"
                      @click="$root.$emit('bv::show::modal','ActivatePromoCode')"
              >
                Пополнение с промо-кода
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="alert alert-primary">
        <div class="col-12 resp-loader loading">
          <i class="fa fa-spinner fa-spin fa-fw"/>
        </div>
      </div>
    </div>
    <ActivatePromoCode></ActivatePromoCode>
  </div>

</template>

<script>
import PaymentMethod from './PaymentMethod.vue'
import ActivatePromoCode from './ActivatePromoCode.vue'
import Alert from '@/components/Alert'

export default {
  name: 'DepositBlock',
  components: {
    PaymentMethod,
    Alert,
    ActivatePromoCode
  },

  data() {
    return {
      isCardAndSbp: false,
      amountAlert:false,
      isDropdownOpen: false,
      isPromoCode: false,
      selectedOption: {
        label: '',
        image: ''
      },
      sum: null,
      selectedMethod: {
        id: null,
        module: '',
        name: '',
        min_amount: null,
        max_amount: null,
        picture: ''
      },
      cardMethod: {
        id: 2,
        name: 'Карта',
        method_type: 'card',
        card_picture: require('@/../public/images/icons/karta.svg')
      },
      sbpMethod: {
        id: 1,
        name: 'СБП',
        method_type: 'sbp',
        card_picture: require('@/../public/images/icons/sbp.svg')
      },
      transferMethod: {
        id: 3,
        name: 'Банковские переводы',
        method_type: 'transfer',
        card_picture: require('@/../public/images/icons/transfer.svg')
      },
      sberPayMethod: {
        id: 4,
        name: 'SberPay',
        method_type: 'sberpay',
        card_picture: require('@/../public/images/icons/transfer.svg')
      },
      selectionChange: null,
      formResponse: null
    }
  },

  computed: {
    userData() {
      return this.$store.getters.userData
    },
    depositMethods() {
      return this.$store.getters.getDepositMethods
    },
    depositSettings() {
      return this.$store.getters.getDepositSettings
    }
  },

  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    promoCode() {
      this.isPromoCode = !this.isPromoCode
    },
    getPictureUrl(url) {
      if (url.card_picture) {
        return url.card_picture;
      } else {
        return this.$_config.baseUrl + (url.picture ?? url.img)
      }
    },
    blockMethod(key) {
      if (!this.userData) {
        this.$root.$emit('bv::hide::modal', 'paymentModal')
        this.$root.$emit('bv::show::modal', 'loginModal')
        return this.$root.$emit('reasonToLogin', 'Для пополнения баланса нужно авторизоваться')
      }
      this.isCardAndSbp = false;
      this.isDropdownOpen = false;
      this.selectedMethod = key
      this.selectedOption.label = key.name
      this.selectedOption.image = key.picture ?? key.card_picture
    },
    backData(method) {
      this.isCardAndSbp = false;
      this.isDropdownOpen = false;

      if (method.method_type === 'card') {
        this.selectedMethod = this.cardMethod
        this.selectedOption.label = this.cardMethod.name
        this.selectedOption.image = this.cardMethod.picture ?? this.cardMethod.card_picture
      } else if (method.method_type === 'sbp') {
        this.selectedMethod = this.sbpMethod
        this.selectedOption.label = this.sbpMethod.name
        this.selectedOption.image = this.sbpMethod.picture ?? this.sbpMethod.card_picture
      } else if (method.method_type === 'transfer') {
        this.selectedMethod = this.transferMethod
        this.selectedOption.label = this.transferMethod.name
        this.selectedOption.image = this.transferMethod.picture ?? this.transferMethod.card_picture
      } else if (method.method_type === 'sberpay') {
        this.selectedMethod = this.sberPayMethod
        this.selectedOption.label = this.sberPayMethod.name
        this.selectedOption.image = this.sberPayMethod.picture ?? this.sberPayMethod.card_picture
      }
    },
    selectMethod(selected) {
      if (!this.userData) {
        this.$root.$emit('bv::hide::modal', 'paymentModal')
        this.$root.$emit('bv::show::modal', 'loginModal')
        return this.$root.$emit('reasonToLogin', 'Для пополнения баланса нужно авторизоваться')
      }
      this.amountAlert = false;
      this.isDropdownOpen = false;
      this.selectedMethod = selected
      this.selectedMethod.module = selected.method_module
      this.selectedMethod.picture = selected.img ?? selected.picture
      this.selectedOption.label = selected.name
      this.selectedOption.image = this.$_config.baseUrl + selected.picture ?? selected.img

      if(selected.method_module === 'adwin'){
        this.amountAlert = true;
      }
      if (selected.method_type === 'card' || selected.method_type === 'sbp' || selected.method_type === 'transfer' || selected.method_type === 'sberpay') {
        this.isCardAndSbp = true;
      }
    },
    payInFormSubmit(e) {
      if (!this.userData) {
        e.preventDefault()
        this.formResponse = {status: 'error', msg: 'Unauthorized'}
        setTimeout(() => {
          this.$router.push({name: 'login'})
        }, 500)
        return
      }
      const payInAmount = parseFloat(this.$refs.depositAmount.value) || 0
      if (!payInAmount > 0) {
        e.preventDefault()
        this.formResponse = {status: 'error', msg: 'Пожалуйста, укажите правильую сумму'}
        return false
      }
      if (!this.selectedMethod.id || !this.selectedMethod.module) {
        e.preventDefault()
        this.formResponse = {status: 'error', msg: 'Пожалуйста выберите метод депозита'}
        return false
      }

      if(parseFloat(this.$refs.depositAmount.value) < this.selectedMethod.min_amount) {
        e.preventDefault()
        this.formResponse = {status: 'error', msg: "Пожалуйста, укажите сумму, превышающую минимальную сумму для выбранного метода депозита."}
        return false
      }

      if(parseFloat(this.$refs.depositAmount.value) > this.selectedMethod.max_amount) {
        e.preventDefault()
        this.formResponse = {status: 'error', msg: "Пожалуйста, укажите сумму, не превышающую максимальную сумму для выбранного метода депозита."}
        return false
      }

      return true
    },
  },

  watch: {
    sum() {
      const payInAmount = parseFloat(this.$refs.depositAmount.value) || 0
      if (payInAmount != this.sum) {
        this.sum = payInAmount
        return this.formResponse = {status: 'error', msg: 'Пожалуйста, укажите правильую сумму'}
      } else {
        return this.formResponse = {}
      }
    }
  },

  mounted() {
    this.$store.dispatch('fetchAvailableDepositMethods')
    this.$store.dispatch('fetchDepositsHistory')
    this.selectedOption.label = this.cardMethod.name
    this.selectedOption.image = this.cardMethod.card_picture
    this.selectedMethod.module = 'nicepay'
  },

}
</script>

<style scoped>
.alert-text {
  font-size: 14px;
  color: #e52a06;
}

.method-block {
  border-right: 2px solid rgb(143, 141, 141);
}
.promo-text {
  margin-top: 25px;
  color: #a7aaad;
  font-size: 15px;
  transition: 0.5s;
  cursor: pointer;
}

.promo-text:hover {
  color: white;
}

.selected-method {
  width: calc(50% - 6px);
}

.deposit-amount-btn,
.col-form-label,
.bonus-text,
.deposit-hint {
  font-size: 12px;
}

.promo-activation-area {
  border-top: 1px solid #333333;
}

#depositAmount,
#depositPromocode {
  background: #1c2028;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  height: 40px;
  padding-left: 35px;
}

@media (max-width: 992px) {
  .back-arrow-block {
    font-size: 20px;
  }

  .back-arrow {
    color: #a7aaad;
  }

  .back-arrow:hover {
    color: white;
  }

  .deposit-amount-btn {
    margin-top: 10px;
  }

  .method-block {
    overflow: unset;
    padding: 0;
  }
  .selected-img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    background-color: #1c2028;
    border: 1px solid #333946;
    border-radius: 5px;
    box-shadow: 1px 3px 3px -2px #20242d;
    color: #aab0bd;
  }
}
</style>
  